<template>
    <section class="comp-origin-container">
        <main class="content">
            <template v-if="isShow">
                <div v-for="(item, index) of item.textArr" :key="index" :class="'p' + (curReadIndex === index ? ' active' : '')">
                    <div class="en" @click="enClick">
                        <span class="text-word" v-for="(word, j) of item.en" :key="j" @click="wordLongPress">{{ word.t }} </span>
                    </div>
                    <div class="cn">{{ item.cn }}</div>
                </div>
            </template>
        </main>
        <div class="b-audio-wrap">
            <Baudio :src="item.audio" @timeupdate="timeupdate" />
        </div>
    </section>
</template>

<script>
import Api from '@/api/index';
import { ref, reactive, watchEffect } from 'vue';
import { OriginText } from './Interface';
import Baudio from '@/components/widgets/BAudio.vue';
export default {
    components: { Baudio },
    props: {
        quesId: String,
        isShow: Boolean,
        // audioSrc: String,
    },
    setup(props) {
        const item = reactive(new OriginText({}));
        const curReadIndex = ref(0);
        // const audioSrc = ref('');
        // const isShow = ref(false);

        watchEffect(() => {
            if (props.quesId) {
                Api.uniModel('origin_text').findOneBy({quesId: props.quesId }).then(res => {
                    if (res) {
                        item.build({
                            ...res,
                            textArr: res.textArr.map((i, index) => ({
                                index: index,
                                en: i.en.split(' ').filter(Boolean).map(j => ({ t: j, s: -1 })),
                                cn: i.cn,
                                timeRange: i.timeRange
                            }))
                        });
                    }
                })
            }
        })
        const wordLongPress = () => {};

        const timeupdate = time => {
            console.log(time);
            const time10 = time * 10;
            for (let i = 0; i < item.textArr.length; i++) {
                if (time10 >= item.textArr[i].timeRange[0] && time10 <= item.textArr[i].timeRange[1]) {
                    curReadIndex.value = i;
                }
            }
        }
        return { curReadIndex, item, wordLongPress, timeupdate };
    }
}
</script>

<style lang="less" scoped>
@import '@/lib/style/var.less';
@audio-padding: 10rpx;
@audio-height: 120rpx;
@step-height: 190rpx;
@green6: #0ECC8B;
@green8: #00805b;
.comp-origin-container {
    position: relative;
    width: 100%; 
    height: 100%;
    padding-bottom: 50px;
    .content {
        border: 1px solid #f0f0f0;
        border-bottom: none;
        border-left: none;
        height: 100%;
        padding: 24px;
        overflow: scroll;
    }
    .b-audio-wrap {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
    }
}
.btn-wrap {
    width: 100%;
    display: flex;
    min-height: 30vh;
    align-items: center;
    justify-content: center;
}
.p {
    margin-bottom: 12px;
    
    .en {
        font-family: 'Times New Roman', Times, serif;
        font-size: 18px;
        color: @grey9;
        display: flex;
        white-space: normal;
        flex-wrap: wrap;
        opacity: .9;
        .text-word {
            word-break: break-all;
            margin: 0 3px;
        }
    }
    &.active {
        background: #fff;
        .en {
            // color: #fe456a;
            color: #0ECC8B;
            color: #0962b6;
            // color: #4a4a4a;
            // color: #000;
            // font-weight: bold;
            white-space: normal;
            opacity: 1;
        }
        .cn { opacity: 1;}
    }
    .cn {
        padding: 0 3px;
        font-size: 15px;
        color: @grey7_8;
        opacity: .9;
        margin-top: 5px;
    }
}

.follow-read {
    .read-item.p {
        padding: 24rpx;
        padding-top: 32rpx;
        padding-bottom: 0;
        .en {
            font-size: 36rpx;
            color: #fff;
            margin: 6rpx 0;
            .text.good {
                color: #0ECC8B;;
            }
            .text.bad {
                color: #fe456a;
            }
            .text.default {
                color: rgba(#fff, .8);
            }
        }
    }
    .idx {
        color: rgba(#fff, .4);
        background: rgba(#fff, .2);
        display: flex;
        align-items: center;
        justify-content: center;
        width: 70rpx;
        height: 32rpx;
        border-radius: 8rpx;
        margin-bottom: 12rpx;
        padding-top: 1px;
        font-size: 22rpx;
    }
    .line {
        border-bottom: 1rpx solid rgba(#fff, .2);
    }
    .sound-wrap {
        position: relative;
        margin-top: 32rpx;
        display: flex;
        .sound { 
            flex: 1;
            padding: 32rpx 0;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 26rpx;
            // color: rgba(#fff, .6);
            color: #fff;
            &.my-sound {
                position: relative;
            }
        }
    }
    .score-container {
        overflow: hidden;
    }
    .score-wrap {
        display: flex;
        margin: 16rpx 16rpx 0;
        border-radius: 16rpx;
        height: 100rpx;
        color: @grey6;
        align-items: center;
        background: #3D3E43;
        transform: translateY(-120%);
        transition: transform 400ms;
        opacity: 0;
        &.toggle-show {
            opacity: 1;
            transform: translateY(0);
        }
        .score {
            flex: 1;
            text-align: center;
            font-size: 24rpx;
            .score-text {
                font-size: 48rpx;
                color: #fff;
            }
        }
        .fluency,.accuracy, .complete {
            flex: 1;
            font-size: 24rpx;
            text-align: center;
            padding-top: 16rpx;
        }
        .fluency {
            position: relative;
        }
    } 
}

.audio-container {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    // background-color: #fff;
    background-color: #313237;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: @audio-height;
    height: calc(@audio-height + env(safe-area-inset-bottom));
    padding: @audio-padding 24rpx;
    padding-bottom: calc(@audio-padding + env(safe-area-inset-bottom));
    box-sizing: border-box;
    .op-item {
        flex: 1;
        text-align: center;
        .icon {
            text-align: center;
            &.strong {
                font-weight: bold;
            }
        }
        &.play-speed-box {
            display: flex;
            align-items: center;
            justify-content: center;
            .play-speed {
                border: 1rpx solid @primary6;
                border: 1rpx solid @green6;
                font-size: 26rpx;
                line-height: 1;
                box-sizing: border-box;
                border-radius: 8rpx;
                padding: 8rpx 12rpx;
                color: @primary6;
                color: @green6;
            }
        }
    }
}

.cursearch {
    padding: 16rpx 16rpx 16rpx 22rpx;
    background: #313237;
    .word {
        color: @primary6;
        color: @green6;
        font-size: 54rpx;
        font-weight: bold;
    }
    .phonetic {
        font-size: 32rpx;
        color: @grey8_9;
        color: @grey4;
        line-height: 2.3;
        display: flex;
        .ph-am {
            margin-left: 20rpx;
        }
    }
    .mean {
        font-size: 32rpx;
        color: @grey10;
        color: @grey4;
        padding-bottom: 14rpx;
        line-height: 1.5;
        &-item {
            display: flex;
            .item-bd {
                margin-left: 8rpx;
                flex: 1;
            }
        }
    }
    .sentence {
        color: @grey10;
        color: @grey4;
        padding: 14rpx 0;
        border-top: 2rpx dashed @grey8;
        .en {
            font-size: 34rpx;
            .active {
                font-weight: bold;
                color: @primary6;
                color: @green6;;
            }
        }
        .cn {
            font-size: 32rpx;
            margin-top: 4rpx;
            color: @grey10;
            color: @grey6;
        }
    }
    .add-wrap {
        padding: 20rpx 0 6rpx 0;
        border-top: 2rpx dashed @grey4_5;
        font-size: 32rpx;
        .add-btn {
            text-align: center;
            color: @gold7;
            color: @green6;
            font-weight: bold;
            &.added {
                opacity: .8;
            }
        }
    }
}
.complete-btn {
    width: 65%;
    margin: 32rpx auto 0;
}

.follow-read-tools {
    position: fixed;
    bottom: 32rpx;
    left: 0;
    right: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 200rpx 120rpx 48rpx;
    .icon-circle-wrap {
        border: 1px solid #2BCF7B;
        padding: 16rpx;
        border-radius: 50%;
        .icon {
            line-height: 1;
            color: #2BCF7B;
        }
    }
}

.recorder {
    display: flex;
    justify-content: center;
    position: relative;
    .record-wave-multiple {
        position: absolute;
        left: 0; top: 0;
        width: 80px;
        height: 80px;
    }
    .record-wave-multiple > .wave {
        display: inline-block;
        background-color: @green6;
        border: 0 solid @green6;
        position: absolute;
        top: -20px;
        left: -20px;
        width: 120px;
        height: 120px;
        border-radius: 100%;
        opacity: 0;
        -webkit-animation: circle-wave-multiple 1.8s cubic-bezier(.41,.4,.67,1) 0s infinite;
        animation: circle-wave-multiple 1.8s cubic-bezier(.41,.4,.67,1) 0s infinite;
    }
    .record-wave-multiple > .wave:nth-child(2) {
        -webkit-animation-delay: .6s;
        animation-delay: .6s;
    }
    .record-wave-multiple > .wave:nth-child(3) {
        -webkit-animation-delay: 1.2s;
        animation-delay: 1.2s;
    }
}

@keyframes circle-wave-multiple {
    0% {
        opacity: 0;
        transform: scale(.4);
    }
    5% {
        opacity: .5;
    }
    100% {
        opacity: 0;
        transform: scale(1);
    }
}


.choose-dialog-wrap {
    .choose-dialog {
        .img {
            width: 100%;
            display: block;
        }
        .title {
            color: @primary6;
            color: @green6;
            font-weight: bold;
            font-size: 38rpx;
            padding-top: 46rpx;
        }
    }
    .weui-dialog__btn_default {
        color: @grey8_9;
    }
    .weui-dialog__btn_primary {
        color: @primary6;
        color: @green6;;
    }
}

</style>