<template>
    <section :class="'comp-ques-wrap' + (ques.q && ques.q.length >= 3 ? ' double' : '')">
        <div class="ques-item" v-for="(it, qindex) of ques.q" :key="it._id">
            <div class="ques-title-audio">
                <div v-if="it.title && !it.isHideTitle" class="item-title">
                    <div class="index">{{ ques.qIndex + qindex }}. </div>
                    <div class="text">
                        <span class="text-word" v-for="(word, i) of it.title" :key="i" @click="wordLongPress">{{ word }} </span>
                    </div>
                </div>
                <div v-else class="flex1 item-title2">{{ ques.qIndex + qindex }}. 请仔细听题</div>
            </div>
            <div class="options">
                <div :class="'option ' + (isShowAnswer ? (option.isAnswer ? 'correct' : option.isChoose ? 'error' : '') : (option.isChoose ? 'active' : ''))" v-for="(option, j) of it.optionCns" :key="j"  @click="optionClick(qindex, j)">
                    <div class="option-index">{{ j === 0 ? 'A' : j === 1 ? 'B' : j === 2 ? 'C' : 'D'}}. </div>
                    <div class="option-text">
                        <span class="text-word" v-for="(word, l) of option.en" :key="l" @click="wordLongPress">{{ word }} </span>
                    </div>
                </div>
            </div>
        </div>
        <div class="origin-btns">
            <div class="btn" @click="switchAnswer">{{ isShowAnswer ? '隐藏答案' : '查看答案'}}</div>
            <div class="btn mlr12" @click="switchOrigin">{{ isShowOrigin ? '隐藏原文' : '查看原文'}}</div>
        </div>
        <!-- <div class="b-audio-wrap">
            <Baudio />
        </div> -->
    </section>
</template>

<script>
import Api from '@/api/index';
import { ref, reactive, watchEffect } from 'vue';
import { QuesModel } from './Interface.js'
// import Baudio from '@/components/widgets/BAudio.vue';
const optionIndexLabel = (index) => {
    if (index === 0) return 'A';
    if (index === 1) return 'B';
    if (index === 2) return 'C';
    if (index === 3) return 'D';
    if (index === 4) return 'E';
};

// const optionLabelIndex = (letter) => {
//     if (letter === 'A') return 0;
//     if (letter === 'B') return 1;
//     if (letter === 'C') return 2;
//     if (letter === 'D') return 3;
//     if (letter === 'E') return 4;
// };
export default {
    // components: { Baudio },
    emits: ['switchOrigin', 'updateAudioSrc', 'updateTitle'],
    props: {
        quesId: {
            type: String,
        },
        isShowOrigin: {
            type: Boolean,
            default: false,
        }
    },
    setup(props, { emit }) {
        const ques = reactive(new QuesModel({}));
        const isShowAnswer = ref(false);
        const switchAnswer = () => {
            isShowAnswer.value = !isShowAnswer.value;
            for (let i = 0; i < ques.q.length; i++) {
                const q = ques.q[i];
                for (let j = 0; j < q.optionCns.length; j++) {
                    const option = q.optionCns[j];
                    if (q.answer.includes(j)) option.status = 'correct';
                    else if (option.status === 'active') {
                        option.status = 'error';
                    }
                }
            }
        }

        const optionClick = (qindex, optIndex) => {
            console.log(qindex, optIndex);
            try {
                const q = ques.q[qindex];
                // 如果这道题的已选长度，大于答案长度，不能再选了
                if (q.optionCns.filter(o => o.isChoose).length >= q.answer.length) return;

                const option = q.optionCns[optIndex];
                option.isChoose = true;
                // if ( q.answer.includes(optionIndexLabel(optIndex))) {
                //     option.status = 'correct';
                // } else {
                //     option.status = 'error';
                //     q.answer.forEach(letter => {
                //         q.optionCns[optionLabelIndex(letter)].status = 'correct';
                //     });
                // }
                // q.userAnswer.push(this.optIndexLabel(optIndex))

                // 如果全部答完，需上报答案
                // if (this.data.user.isPro) {
                //     if (!ques.q.some(q => q.userAnswer.length <= 0)) {
                //         // UniApi.appCloud('history', 'ANSWER', {
                //         //     kitpackId: item.kitpackId,
                //         //     kitName: item.kitName,
                //         //     qId: item._id,
                //         //     sectionTitle: item.sectionTitle,
                //         //     qIndex: item.qIndex,
                //         //     userAnswer: item.q.map(i => i.userAnswer),
                //         //     rightCount: item.q.map((item) => item.answer.join('') === item.userAnswer.join('')).filter(Boolean).length,
                //         // })
                //         // // 增加今日答题数
                //         // UniApi.callFunction('learnRecord', { method: 'ADD_QUES', params: { qId: item._id, qNum: item.q.length }})
                //     }
                // }
                
            } catch (error) {
                console.log(error, error);
            }
            
        }
        const updateQues = (quesId) => {
            Api.uniModel('ques').findOneById(quesId).then(res => {
                ques.build({
                    ...res,
                    q: res.q.map(q => ({
                        // ...i,
                        answer: q.answer,
                        isHideTitle: q.isHideTitle,
                        timeRange: q.timeRange,
                        title: q.title.split(' '),
                        userAnswer: [],
                        optionCns: (q.optionCns || []).map((o, index) => ({ 
                            isChoose: false,
                            isAnswer: q.answer.includes(optionIndexLabel(index)),
                            en: (o.en || '').split(' '),
                            cn: o.cn,
                        }))    
                    }))
                });
                emit('updateTitle', { kitId: res.kitId, title: `${res.sectionTitle} ${res.qIndex}~${res.qIndex + res.q.length}`})
                emit('updateAudioSrc', res.originAudio)
            })
        }
        watchEffect(() => {
            if (props.quesId) {
                updateQues(props.quesId);
                isShowAnswer.value = false;
            }
        })
        const switchOrigin = () => emit('switchOrigin');
        const qAudioClick  = () => {}
        return { 
            isShowAnswer, switchOrigin,
            ques, qAudioClick, optionClick, switchAnswer };
    }
}
</script>

<style lang="less" scoped>
@import '@/lib/style/var.less';
@headerH: 80px;
@footerH: 55px;
@gap-lr: 5px;
@radius-level1: 6px;
@green6: #0ECC8B;
@green8: #00805b;

.cl_green6 {
    color: @green6 !important;
}
.cl_green8 {
    color: @green8 !important;
}
.comp-ques-wrap {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    width: 360px;
    min-width: 30vw;
    height: 100%;
    box-sizing: border-box;
    &.double {
        width: 720px;
        .ques-item {
            width: 50%;
            // height: 50%;
        }
    }
    .origin-btns {
        cursor: pointer;
        position: absolute;
        bottom: 12px;
        left: 0;
        right: 0;
        text-align: center;
        color: @grey6_7;
        font-size: 28rpx;
        text-decoration: underline;
        display: flex;
        justify-content: center;
    }
}
.ques-item {
    position: relative;
    width: 100%;
    flex-shrink: 0;
    flex-grow: 1;
    padding: 12px 12px 0;
    box-sizing: border-box;
    .ques-title-audio {
        display: flex;
        align-items: center;
        line-height: 1.6;
        color: @grey7;
        .item-title {
            font-size: 16px;
            color: #fff;
            color: @grey8;
            display: flex;
            flex-wrap: wrap;
            .index {
                opacity: .5;
                margin-right: 4rpx;
            }
            .text {
                flex: 1;
                display: flex;
                flex-wrap: wrap;
                text {
                    word-break: keep-all;
                }
            }
        }
    }
    .item-title2 {
        font-size: 14px;
        color: #AAACAB;
        color: @grey7;
        padding: 12px 0 4px 8px;
    }
    .options {
        .option {
            cursor: pointer;
            position: relative;
            background: #fff;
            background: @grey3;
            font-size: 17px;
            border-radius: @radius-level1;
            display: flex;
            align-items: center;
            margin: 8px 0;
            min-height: 48px;
            font-family: 'Times New Roman', Times, serif;

            &-index {
                padding: 0 5px 0 6px;
                text-align: center;
                color: #AAACAB;
                color: @grey7;
            }
            &-text {
                color: #fff;
                color: @grey9;
                display: flex;
                flex-wrap: wrap;
                .text-word {
                    word-break: keep-all;
                    margin: 0 2px;
                } 
            }
            &.active {
                background: @grey8;
                .option-text { color: #fff ;}
                .option-index { color: #fff ;}
            }
            &.error { 
                background: @red5;
                .option-text { color: #fff ;}
                .option-index { color: #fff ;}
                &:after {
                    position: absolute;
                    content: '';
                    width: 27px;
                    height: 27px;
                    right: 10px;
                    top: 50%;
                    transform: translateY(-50%) scale(0.8);
                    color: #fff;
                    // 36 * 38
                    background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAmCAMAAACbFsmhAAAAmVBMVEUAAAD////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////VHQRUAAAAMnRSTlMAA/nt4NEeEOTWysG2ZE4vGhQMCQb28drGu6ujnoN8WkhAOiwnlpLor5l2bmxVKSWFhB0Y9ToAAAGASURBVDjLfZTZloIwEETDqgKKrCKgguK+zfT/f9x0aBjRJNaLVOWexFQrrCmWIfuicFk0rASwAjUTWAAl0zyAha1i7AWAp+F+DsB4J2d2YwAnbHdE2tjKmK2BpwTdnsgnlchUCZ5h985PAfTNJ7PRAVL/5Wv0cH1nrhjp9TC5A2o1TFY8ubM33Xi213qr7bm/sQ/98DSPycQ5d79MUMnzZUijAFQpMrx6lBNgcVgvFS0qygA1se0JoLKISTVqV02zZUdyhqonUdGKgRrEGMLAhQqpVqWm0GmqZtbwr7WKOcBABykSF7R6PtNnEUuq9GjtpGknevIiociMVtwIeZees49C5xYAFU3Vk7PmQ+ZpUmp26bz3zxfjH7uiH33y6Ko/+n1Sp5Qk28G/KaEsrcnPdPL6jLwsner9KOQj0jG/CIMQhnRhrnoMeyC5rKKHXBMhLae1ijGnhWPpONtjHH4NXu1I8avng2ivZ1HRoqh6i94eRsOUaowNfT+ffZGPN/oDO8hL9cPkExgAAAAASUVORK5CYII=') no-repeat;
                    background-size: 100% auto;
                    opacity: .8;
                }
            }
            &.correct { 
                background: #02AF42;
                .option-text { color: #fff ;}
                .option-index { color: #fff ;}
                &:after {
                    position: absolute;
                    content: '';
                    width: 33px;
                    height: 27px;
                    right: 10px;
                    top: 50%;
                    transform: translateY(-50%) scale(0.8);
                    color: #fff;
                    // 66 * 54
                    background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEIAAAA2CAYAAABz508/AAAAAXNSR0IArs4c6QAAA9hJREFUaAXtml2ITVEUx+eOmcaMTGkwGlGUj8HMCw+TGhIy8+RleJDElAwvPOEBJZNE80CJPCokociDF5Jvygvx4GNIUb4/8z3Xb8Vpdsfd95y9z5lzjj2z6t/Ze+2111r/dW/tu9e5JSX9RPL5fCM4Az6DR2BlP6HeSxPSbeAr8Et9r5XjI5h3gF/+Cvydz3Sc/h96kN2kKYCnHut0IWCZA3s8tprnBdeLUA7xIxrynvoDgwZnCwG5IeCsx1bz/Im+1eUi1EDwuoa8ql7tchHGwPSeylYz7nK5CPWQfqohrqpPMil1shAQawKvVbaa8U30Va4WoQVynzTEVfUTJqNcLcISyH1X2WrG79FPc7UIayHXoyGuqn8wWeBqETpVpgHjVc4VAcKDwIEA4uryTheLUAHDEyrLgPFx1nNOFQJC1eA8CCs3MKx0rQi1kLoVtgLYPQa1rhVhPKQeGBThHbZTXSuC9BafGxRBjsn5sRYBh6n+Fid+M5BP10TiacISsQxIS+s+kN6eXGC2gPJYqxzgjHgLwRdgIjsC3IZfJupeTeRr6OvCe7K3JE47kIaJiRzDOL5jEmfSttLJMxaa7CkG78T/Bl3wInr5kOI9JnH4skhAWZL3ASuCKZlZ4FMarF3AVLrZMNIsWghrnB4Mmclu7MpCuAw0ET8GcdX03jKZEhjAxgDHclx9U6MVGZ9jrcYmjreH/VVAXr2Zily753p++uRJgHUGWXVj22iTCPuGgcvARtptYhrvIbPTBtlJZ6jNJAj2o8Edgxiq6XaTWJFsiSrt8DBNUDXBbUwCjzBsJgK5C9jIUTYFxohE3r+ZgLOA6Xl+ij3Vfl/enLUZ4AWwkStsGuz5SvRJ4M0WGd9lzwR/oujmgY8W/mTLQzDC7zOxOcFLgZwOpiJHW4uXKOPFIOxp5I8lviZ7vlJ7kkQdsPk6y11lPVgDZGwjckzOSY28PzDJtIIeGyYR9yz355L6HEK7IpIy3d6ZOulCCcBC/msQ5g2zKeFC9vKfhmSPyUKkdTqSGwdMmyWFiBbTXWKxQpdDZvQkuagYi4hr0pscnhmyQYmQ7P6IhAttf4NyUlDsTK2TcCW4XYiNpU5+Y8zOFMmwyZB4PZB/rsYhy8LGzaQdFZD+YlTZmklypklRhcMRKnHINF5m7SnCUCDtf1O5yIbsH5MmlYfQdGByqZLCRWrzmeSXqC3Ewrb45A9f/1zTE022r4NBUBozxUS+Nc19nUfq/iEZ1OJbmnqSSSVAMRqAv98pV/iNSeUQNU5stz1Iy9unDiCvCF+Bfblc7irPARmowH9Ygd9B9xkEedU1rAAAAABJRU5ErkJggg==') no-repeat;
                    background-size: 100% auto;
                    opacity: .8;
                }
            }
        }
    }
}
@audio_w: 86rpx;
.audio-container {
    position: fixed;
    bottom: 16px;
    left: (750rpx - @audio_w)/2;
    width: @audio_w;
    height: @audio_w;
    background-color: rgba(#eee, .5);
    margin-bottom: calc(env(safe-area-inset-bottom));
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    .icon {
        color: #313237;
        display: block;
        &.strong {
            font-weight: bold;
        }
    }
    .icon-pause3 {
        margin-top: 4rpx;
        font-size: 72rpx;
    }
    .icon-play3 {
        margin-left: 10rpx;
        margin-top: 4rpx;
        font-size: 60rpx;
    }
}

.cursearch {
    padding: 28rpx 16rpx 16rpx 22rpx;
    background: #13222f;
    background: #313237;
    .word {
        color: @primary6;
        color: @green6;
        font-size: 54rpx;
        font-weight: bold;
    }
    .phonetic {
        font-size: 32rpx;
        color: @grey8_9;
        color: @grey4;
        line-height: 2.3;
        display: flex;
        .ph-am {
            margin-left: 20rpx;
        }
    }
    .mean {
        font-size: 32rpx;
        color: @grey10;
        color: @grey4;
        padding-bottom: 14rpx;
        line-height: 1.5;
        &-item {
            display: flex;
            .item-bd {
                margin-left: 8rpx;
                flex: 1;
            }
        }
    }
    .sentence-btn {
        color: @grey8;
        color: @grey4;
        text-align: right;
        margin-bottom: 24rpx;
        text-decoration: dashed;
    }
    .sentence {
        color: @grey10;
        color: @grey4;
        padding: 14rpx 0;
        border-top: 2rpx dashed @grey8;
        .en {
            font-size: 34rpx;
            .active {
                font-weight: bold;
                color: @primary6;
                color: @green6;
            }
        }
        .cn {
            font-size: 32rpx;
            margin-top: 4rpx;
            color: @grey10;
            color: @grey6;
        }
    }
    .add-wrap {
        padding: 20rpx 0 6rpx 0;
        border-top: 2rpx dashed @grey4_5;
        font-size: 32rpx;
        .add-btn {
            text-align: center;
            color: @gold7;
            font-weight: bold;
            &.added {
                opacity: .8;
            }
        }
    }
}
@keyframes slideInDown {
    0% {
        opacity: 0;
    }
    20%, 80% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

.top-tips {
    top: 76rpx
}

.b-audio-wrap {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
}


</style>