
<style lang="less" scoped>
@import '@/lib/style/var.less';
.listen-container {
    height: 100vh;
    display: flex;
    flex-direction: column;
    background: #fff;
    .header {
        // height: 56px;
        display: flex;
        align-items: center;
        padding: 16px 32px;
        border-bottom: 1px solid #f0f0f0;
        .title {
            font-size: 16px;
            font-weight: bold;
            flex: 1;
        }
        .orderLable {
            color: @grey7;
        }
        .btns {
            flex: 3;
            display: flex;
            justify-content: center;
            .ques-title {
                // flex: 1;
                margin: 0 24px;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
        .all-ques-btn {
            text-align: right;
            text-decoration: underline;
            cursor: pointer;
        }
    }
    .main {
        height: 0;
        flex: 1;
        display: flex;
        flex-direction: column;
        .main-content {
            background: #fff;
            flex: 1;
            height: 0;
            overflow: scroll;
            padding: 16px;
            display: flex;
            height: 100%;
            justify-content: center;
            .ques-wrap {
                border: 1px solid #f0f0f0;
                // margin-right: 16px;
                padding: 0 12px;
                height: 100%;
            }
            .origin-wrap {
                flex: 1;
                height: 100%;
            }
        }
    }
}
.choose-kitpack {
    .title {
        color: #4a4a4a;
        font-size: 40px;
        text-align: center;
        margin: 34px 0;
        font-weight: bold;
        user-select: none;
    }
    .kitpacks {
        margin-top: 100px;
        flex: 1;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 17px;
        .kitpack {
            text-align: center;
        }
    }
    .kitpack {
        border: 1px solid #f0f0f0;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 20px;
        width: 280px;
        height: 140px;
        margin: 0 24px;
        font-weight: bold;
        transition: all 300ms;
        cursor: pointer;
        user-select: none;
        &:hover {
            box-shadow: 0 2px 8px rgb(0 0 0 / 9%);
            transform: translateY(-10px);
            font-size: 22px;
        }
        &:active {
            background: @grey4;
        }

    }
}

.buy-modal-body {
    display: flex;
    width: 100%;
    justify-content: center;
}
</style>
<template>
    <section class="listen-container">
        <header class="header">
            <div class="title">{{ curKitpack.name ? `正在练习: ${curKitpack.name}` : '真题听力训练' }}</div>
            <div class="flex1 btns" v-show="curKitpack.id">
                <a-button size="default" type="dashed" @click="preQues">上一题</a-button>
                <div class="ques-title">{{curQuesTitle}}</div>
                <a-button size="default" type="primary" @click="nextQues">下一题</a-button>
            </div>
            <a-popover v-model:visible="isShowAllQues" title="" trigger="click">
                <template #content>
                    <a-list style="max-height: 50vh; overflow: scroll;">
                        <a-list-item>1</a-list-item>    
                    </a-list>
                </template>
                <!-- <div class="all-ques-btn" v-show="curKitpack.id" @click="isShowAllQues = true;">全部真题</div> -->
                <!-- <a-button type="primary">Click me</a-button> -->
            </a-popover>
        </header>
        <main class="main">
            <template v-if="curKitpack.id">
                <section class="main-content">
                    <section class="ques-wrap">
                        <Ques class="flex1" :quesId="curQuesId" @updateAudioSrc="updateAudioSrc"  @updateTitle="updateCurQuesTitle" :isShowOrigin="isShowOrigin" @switchOrigin="isShowOrigin = !isShowOrigin"/>
                    </section>
                    <section class="origin-wrap">
                        <OriginText :isShow="isShowOrigin" :quesId="curQuesId" :audioSrc="curQuesAudioSrc"/>
                    </section>
                </section>
            </template>

            <section v-else class="choose-kitpack">
                <div class="title">选择听力难度</div>
                <div class="kitpacks">
                    <div @click="curKitpackChange(kitpack._id)" class="kitpack" v-for="(kitpack, i) of kitpacks" :key="i">{{kitpack.name}}</div>
                </div>
            </section>
        </main>

        <a-modal v-model:visible="isShowBuyModal" title="解锁内容" okText="280硬币立即解锁" cancelText="关闭">
            <div>更多习题需开通会员噢！</div>
            <div>解锁内容包括</div>
            <div>1. 高考真题听力题库</div>
            <div>2. 四级真题听力题库</div>
            <div>3. 六级真题听力题库</div>
            <div>同时会开通微信小程序「闪题四六级」会员！</div>
            <template #footer>
                <div class="buy-modal-body">
                    <a-button type="dashed" style="margin-right: 16px;" @click="isShowBuyModal = false;">知道了</a-button>
                    <a-button type="primary" @click="buy">立即开通</a-button>
                </div>
            </template>
        </a-modal>
    </section>
</template>

<script>
import Api from '@/api/index';
import { useRouter } from 'vue-router';
import { ref, reactive, computed, inject } from 'vue';
import Ques from './Ques';
import OriginText from './OriginText.vue';
import  { message } from 'ant-design-vue';
const quesMap = {}; // { [quesId]: value };
console.log('quesMap', quesMap);
export default {
    name: 'viewListen',
    components: { Ques, OriginText },
    setup() {
        const router = useRouter();
        const { user } = inject('user');
        const kitpacks = ref([]);
        const curKitpack = reactive({ id: '', name: '', kits: [], quesIds: [] });
        const curQuesIndex = ref(-1);
        const curQuesTitle = ref('');
        const curQuesAudioSrc = ref('');
        const curQuesId = computed(() => curKitpack.quesIds[curQuesIndex.value] || '');

        const isShowAllQues = ref(false);
        
        const updateCurQuesTitle = ({kitId, title}) => {
            console.log('updateCurQuesTitle', kitId, title)
            const kit = curKitpack.kits.find(i => i.id === kitId);
            curQuesTitle.value = !kit ? '' : `${kit.name} ${title}`;
        }
        const isShowOrigin = ref(false);
        const curKitpackChange = (id) => {
            const item = kitpacks.value.find(i => i._id === id);
            curKitpack.id = item._id;
            curKitpack.name = item.name;
            curKitpack.kits = [...item.kits];
            curKitpack.quesIds = [...item.quesIds];
            curKitpack.randomQuesIds = [...item.quesIds].sort(() => Math.random() - 0.5);
            curQuesIndex.value = 0;
        }
        // const updateCurQues = id => {
        //     Api.uniModel('ques').findOneById(id).then(res => {
        //         curQues.build({
        //             ...res,
        //             q: res.q.map(i => ({
        //                 ...i,
        //                 title: i.title.split(' '),
        //                 userAnswer: [],
        //                 optionCns: (i.optionCns || []).map(o => ({ status: '', en: (o.en || '').split(' '), cn: o.cn, }))    
        //             }))
        //         });
        //     })
        // }
        // watchEffect(() => {
        //     if (curQuesIndex.value >= 0) {
        //         updateCurQues(curKitpack.quesIds[curQuesIndex.value]);
        //     }
        // })
        Api.uniModel('kitpack').findAll().then(res => kitpacks.value = res);

        const updateAudioSrc = src => {
            console.log('updateAudioSrc', src);
            console.log(src);
            curQuesAudioSrc.value = src;
        }

        const isShowBuyModal = ref(false);
        const preQues = () => {
            if (curQuesIndex.value <= 0) return message.info('已经是第一题了!');
            else curQuesIndex.value--;
        }
        const nextQues = () => {
            if (user.isPro) {
                curQuesIndex.value++;
                isShowOrigin.value = false;
            } else {
                isShowBuyModal.value = true;
            }
        }

        const buy = () => {
            router.push('/user');
            // if (user.coin < 10 ) message.error('硬币不足');
            // else {
            //     Api.uniModel('user').req('BUY_MODULE', { openid: user.openid, module: 'LISTENING' }).then(res => {
            //         console.log('res', res);
            //         updateUser();
            //         message.success('解锁成功');
            //         isShowBuyModal.value = false;
            //     }).catch(err => {
            //         message.error(err.msg);
            //     })
            // }
        }

        return {
            user,
            isShowAllQues,
            curQuesIndex, curQuesId, curQuesTitle, curQuesAudioSrc, updateAudioSrc,
            preQues, nextQues, isShowBuyModal, buy,
            kitpacks, curKitpack, curKitpackChange, isShowOrigin, updateCurQuesTitle }
    }
}
</script>