export class QModel {
    constructor(opt = {}) {
        this.build(opt)
    }
    build(opt) {
        this.id = opt.id || '';
        this.isHideTitle = opt.isHideTitle === false ? false : true;
        this.title = opt.title || '';
        this.options = opt.options || [];
        this.optionCns = opt.optionCns || [];
        this.answer = opt.answer || [];
        this.userAnswer = opt.userAnswer || [];
        this.timeRange = opt.timeRange || [0, 0]
    }
}
export class QuesModel {
    constructor(opt) {
        this.build(opt)
    }
    build(opt = {}) {
        if (opt._id) {
            this._id = opt._id;
        }
        this.kitId = opt.kitId || '';
        this.kitpackId = opt.kitpackId || '';
        this.sectionTitle = opt.sectionTitle || '';
        this.qIndex = Number(opt.qIndex) || 0;
        this.quesType = opt.quesType || 'LISTING'; // 'normal' || 'listing'
        this.q = [...(opt.q || [])].map(i => new QModel(i));
        // 以下字段仅仅英语听力题才有
        this.originAudio = opt.originAudio || '';

        this.theme = opt.theme || '';
    }
}

export class OriginText {
    constructor(opt) {
        this.build(opt)
    }
    build(opt) {
        if(opt._id) this._id = opt._id;
        this.quesId = opt.quesId || '';
        this.textArr = opt.textArr || [];
        this.audio = opt.audio || '';
        this.textType = opt.textType || 'normal'; 'normal'; // 'normal' || 'talk'
    }
}
