<template>
    <section class="aaudio-container">
        <main class="main">
            <PauseCircleOutlined v-if="audioInfo.isPlaying" @click="pauseAudio" style="color: #999; font-size: 32px"/>
            <PlayCircleOutlined v-else @click="playAudio" style="color: #999; font-size: 32px"/>
            <div class="slider-wrap">
                <a-slider id="test" v-model:value="audioInfo.curTime" :max="audioInfo.allTime" :min="0" :step="1" :tooltipVisible="false" style="margin: 0 3px;" @change="slideChange"/>
            </div>
            <!-- <div class="audio-time">{{ audioInfo.curTimeStr }} / {{ audioInfo.allTimeStr }}</div> -->
        </main>
    </section>
</template>

<script>
import { reactive, ref, watch } from 'vue';
export default {
    props: {
        src: { type: String, default: ''},
    },
    setup(props, { emit }) {
        const audio = new Audio();
        const audioInfo = reactive({
            isPlaying: false,
            allTime: 0,
            allTimeStr: '00:00',
            curTime: 0,
            curTimeStr: '00:00',
        })
        if (props.src) audio.src = 'https://cdn.amathclass.cn' + props.src;
        watch(props, (newProps) => {
            if (newProps.src) {
                if (audio.src !== newProps.src) {
                    console.log('audio.paused', audio.paused)
                    if (!audio.paused) audio.pause(); // 换src了，需要暂停
                    audio.src = 'https://cdn.amathclass.cn' + newProps.src || '';
                    audioInfo.isPlaying = false;
                } else {
                    if (audio.currentTime !== newProps.startTime) {
                        audio.currentTime = newProps.startTime;
                        if (audio.paused) audio.play();
                    }
                }
            }
        })
        audio.onloadedmetadata = function(e) {
            audioInfo.allTime = Math.round(e.target.duration * 100);
            // const minutes = Math.round(e.target.duration / 60);
            // const seconds = Math.round(e.target.duration % 60);
            // audioInfo.allTimeStr = String(minutes) + ':' + String(seconds);
        }
        const onTimeupdate = function() {
            audioInfo.curTime = Math.round(audio.currentTime * 100);
            emit('timeupdate', audio.currentTime);
            // const minutes = Math.round(audio.currentTime / 60);
            // const seconds = Math.round(audio.currentTime % 60);
            // audioInfo.curTimeStr = String(minutes) + ':' + String(seconds);
        }
        audio.addEventListener('timeupdate', onTimeupdate);
        audio.onplay = function() { audioInfo.isPlaying = true; }
        audio.onpause = function() { console.log('audio paused2'); audioInfo.isPlaying = false; }
        const playAudio = () => audio.play();
        const pauseAudio = () => audio.pause();
        const sliderValue = ref(0);
        const slideChange = (e) => {
            audio.currentTime = e / 100;
        }
        return { sliderValue, audioInfo, playAudio, pauseAudio, slideChange }
    }
}
</script>

<style lang="less" scoped>
.aaudio-container {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    // z-index: 900000;
    transition: all .2s;
    .main {
        height: 100%;
        max-width: 1000px;
        padding: 0 16px;
        width: 980px;
        min-width: 320px;
        background: rgba(0, 0, 0, .4);
        background: #fff;
        border: 1px solid #ececec;
        border-left: none;
        // border-radius: 5px;
        display: flex;
        align-items: center;
        .slider-wrap {
            padding: 10px 10px;
            flex: 1;
            .title {
                color: #fff;
                font-size: 14px;
            }
        }
        .audio-time {
            color: #9c9c9c;
            font-size: 14px;
        }
    }
}

</style>